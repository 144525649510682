import { object, z } from "zod"
import {
  AuthenticationErrorEnum,
  TContractEnterpriseStatus,
  TDynamicObject,
} from "../type"

export const govmartUserSchema = z.object({
  id: z.string(),
  name: z.string(),
  picture: z.string().optional(),
  username: z.string(),
  phone: z.string().optional(),
  email: z.coerce.string().email().optional(),
  /**
   * Enum of `NonPenyediaRoleEnum` and `PenyediaRoleEnum`.
   * Reference [Type](https://gtp-tyk-sa.eproc.dev/graphql/playground)
   */
  role: z.string(),
  fullName: z.string(),
  institutionId: z.string(),
  institutionType: z.string().optional(),
  isTest: z.boolean().optional(),
  legacyAppsUserId: z.union([z.string(), z.number()]).optional(),
  legacyAppsUsername: z.string().optional(),
  personaId: z.string(),
  personaOwner: z.string(),
  permissions: z.array(z.string()).optional(),
  enterpriseStatus: object({
    status: z
      .union([z.string(), z.nativeEnum(TContractEnterpriseStatus)])
      .optional(),
    rejectReason: z.string().optional().nullish(),
  })
    .optional()
    .nullish(),
  /**
   * @deprecated use 'institutionId' instead.
   */
  buyerId: z.string().optional(),
  /**
   * @deprecated use 'institutionId' instead.
   */
  sellerId: z.string().optional(),
})

export const accountUserSchema = z.object({
  id: z.string(),
  isSeller: z.boolean(),
  username: z.string(),
  /**
   * Enum of `Profession`.
   * Reference [Type](https://gtp-tyk-sa.eproc.dev/graphql/playground)
   */
  type: z.enum(["ASN", "NON_ASN", ""]).optional(),
  name: z.string().optional(),
  status: z.string().optional(),
  /**
   * Enum of `UserStatus`.
   * Reference [Type](https://gtp-tyk-sa.eproc.dev/graphql/playground)
   */
  profileStatus: z.string().optional(),
})

export const govmartSchema = z.object({
  token: z
    .object({
      accessToken: z.string().optional(),
    })
    .optional(),
  error: z.nativeEnum(AuthenticationErrorEnum).optional(),
  expires: z.string().optional(),
  user: govmartUserSchema.optional(),
})

export const accountSchema = z.object({
  token: z
    .object({
      accessToken: z.string().optional(),
    })
    .optional(),
  error: z.nativeEnum(AuthenticationErrorEnum).optional(),
  expires: z.string().optional(),
  user: accountUserSchema.optional(),
})
